import { http } from "./axiosUtils";

const usersPath = "users";
export const getUser = () => http.get(usersPath);

export const createUser = (user) => http.post(usersPath, user);

export const updateUser = (user) => http.put(usersPath, user);

export const deleteUser = () => http.delete(usersPath);

export const getUserProgress = (courseId) =>
  http.get(`${usersPath}/progress/course/${courseId}`);

export const updateUserProgress = (courseId, lessonId) =>
  http.put(`${usersPath}/progress/course/${courseId}`, { lessonId });
