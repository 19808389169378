import store from "../store";
import { authActions, authGetters } from "@/store/auth";
import { userActions, userGetters } from "@/store/user";
import Vue from "vue";

export async function routePermissions(to, from, next) {
  const userIsLogged = await fetchUserAuthentication();
  const destination = !userIsLogged
    ? `/public/auth?forwardTo=${to.path}`
    : await destinationForLoggedUser(to);
  store.commit("setFirstVisit", !userIsLogged);
  next({ path: destination, query: to.query });
}

async function destinationForLoggedUser(to) {
  const courseId = to.params.courseId;
  const currentPath = to.path;
  const isUserFirstTime = !(await fetchUser());

  if (isUserFirstTime && currentPath !== "/app/welcome/") {
    return "/app/welcome/";
  }
  if (courseId && !store.getters[authGetters.getCourseClaim](courseId)) {
    Vue.prototype.$snackbar.error("No tienes acceso al curso " + courseId);
    return "/";
  }
  if (store.getters.isFirstVisit && currentPath === "/app/home") {
    const oldPath = store.getters.getOldPath;
    const queryParamsIndex = oldPath.indexOf("?");
    return queryParamsIndex === -1
      ? oldPath
      : oldPath.substring(0, queryParamsIndex);
  }
}

async function fetchUserAuthentication() {
  try {
    if (store.getters.isFirstVisit) {
      await store.dispatch(authActions.userSession);
      await store.dispatch(authActions.cognitoData);
    }
    return store.getters[authGetters.userIsLogged];
  } catch (e) {
    if (e.code === "UserNotFoundException") {
      await store.dispatch(authActions.logout);
      return false;
    } else if (e.code === "NetworkError") {
      return store.getters[authGetters.userIsLogged];
    }
  }
}

async function fetchUser() {
  try {
    if (store.getters.isFirstVisit) {
      await store.dispatch(userActions.get);
    }
  } catch (e) {
    return false;
  }
  return !!store.getters[userGetters.getUser].id;
}
