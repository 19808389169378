import axios from "axios";
import store from "@/store";
import { authActions, authGetters } from "@/store/auth";

const axs = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL + process.env.VUE_APP_STAGE,

  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
// Add the authorization header on requests
axs.interceptors.request.use(
  async (config) => {
    await store.dispatch(authActions.userSession);
    const token = store.getters[authGetters.getLoggedUserToken];
    if (token) {
      config.headers.Authorization = "Bearer " + token;
      config.withCredentials = true;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axs.interceptors.response.use(
  (response) => response.data,
  (error) => Promise.reject(error.response)
);

export const http = axs;
