import { http } from "./axiosUtils";

/**
 *
 * Sends a message to our support system.
 *
 * @param message message data
 * @param message.text message payload.
 * @param message.subject message subject.
 * @param message.email email address to set as contact user contact.
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export const sendMessage = (message) => http.post("users/contact", message);
