<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    overlay-color="#fee8c0"
    overlay-opacity="0.8"
  >
    <v-card style="background-color: #fff7e9">
      <v-toolbar flat>
        <v-btn icon class="hidden-md-and-up" @click="dialog = false">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>

        <v-toolbar-title>¿Necesitas ayuda?</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pt-5">
        <faq-screen />
        <support-form />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { sendMessage } from "../../../services/contact";
import SupportForm from "./SupportForm";
import FaqScreen from "./FaqScreen";

export default {
  name: "HelpDialog",
  components: { FaqScreen, SupportForm },
  data() {
    return {
      dialog: false,
      tab: 0,
    };
  },
  created() {
    this.$support.subscribe(() => (this.dialog = true));
  },
  methods: {
    async sendMessage() {
      this.status = this.formState.SENDING;
      try {
        await sendMessage(this.message);
        this.status = this.formState.SUCCESS;
      } catch (e) {
        this.status = this.formState.ERROR;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
@import "src/styles/custom-colors"
.v-toolbar
  background-color: $dark-purple !important

  .v-toolbar__title
    color: white
    font-size: calc(14px + 1vh) !important
    font-family: 'Prata', 'sans-serif'
</style>
