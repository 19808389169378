<template>
  <v-app>
    <v-main class="global-container">
      <snackbar />
      <loading-modal />
      <help-dialog />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Snackbar from "@/components/global/Snackbar";
import LoadingModal from "@/components/global/LoadingModal";
import HelpDialog from "@/components/global/help/HelpDialog";

export default {
  components: { HelpDialog, Snackbar, LoadingModal },
  metaInfo() {
    return {
      title: "Academia Perros de Ciudad: Tus cursos de entrenamiento canino",
    };
  },
  created() {
    const self = this;
    window.addEventListener("beforeinstallprompt", (installPrompt) => {
      installPrompt.preventDefault();
      self.$store.commit("setInstallPrompt", installPrompt);
    });
  },
};
</script>

<style scoped lang="sass">
.global-container
  background-color: var(--v-cream_light-base)
</style>
