<template>
  <v-dialog v-model="visible" max-width="290">
    <v-card>
      <v-card-text>
        <v-img src="./loading.gif" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
    };
  },
  created() {
    this.$loading.subscribe(
      () => (this.visible = true),
      () => (this.visible = false)
    );
  },
};
</script>

<style scoped></style>
