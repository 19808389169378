// plugins should be the first thing to be initialize due to the Sentry plugin
import "@/plugins";

import Vue from "vue";
import App from "@/App.vue";
import store from "@/store";
import router from "@/router";
import "@/registerServiceWorker";
import { sync } from "vuex-router-sync";
import vuetify from "@/plugins/vuetify";

sync(store, router);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
