import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
} from "amazon-cognito-identity-js";
import { getPreAuthSession, savePreAuthSession } from "@/services/session";

const poolData = {
  UserPoolId: process.env.VUE_APP_COGNITO_USER_POLL_ID,
  ClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
};
const userPool = new CognitoUserPool(poolData);

/**
 * Register
 * @param email
 * @returns {Promise<any>}
 */
export function signup(email) {
  const password = getRandomPassword();
  return promisify((cb) =>
    userPool.signUp(email.toLowerCase(), password, [], null, cb)
  );
}

export function sendAuthCode(email) {
  const loginDetails = new AuthenticationDetails({
    Username: email.toLowerCase(),
  });
  const preAuthCognitoUser = createCognitoUser(email.toLowerCase());
  preAuthCognitoUser.setAuthenticationFlowType("CUSTOM_AUTH");

  return new Promise((resolve, reject) =>
    preAuthCognitoUser.initiateAuth(loginDetails, {
      onSuccess: resolve,
      onFailure: reject,
      customChallenge: async function () {
        try {
          await savePreAuthSession({
            email: preAuthCognitoUser.username,
            session: preAuthCognitoUser.Session,
          });
          resolve();
        } catch (e) {
          reject(e);
        }
      },
    })
  );
}

/**
 * Checks auth code previously sent by the sendAuthCode method.
 * @param code
 * @param email
 * @returns {Promise<unknown>}
 */
export async function checkAuthCode(code, email) {
  const sessionData = email && (await getPreAuthSession(email.toLowerCase()));
  const preAuthCognitoUser = createCognitoUser(
    email.toLowerCase(),
    sessionData.session
  );
  return new Promise((resolve, reject) =>
    preAuthCognitoUser.sendCustomChallengeAnswer(code, {
      onSuccess: resolve,
      onFailure: reject,
      customChallenge: async () => {
        await savePreAuthSession({
          email: preAuthCognitoUser.username,
          session: preAuthCognitoUser.Session,
        });
        const error = new Error("Code not valid, try again");
        error.code = "CodeValidationFail";
        reject(error);
      },
    })
  );
}

export function signOut() {
  userPool.getCurrentUser().signOut();
  localStorage.clear();
}

/**
 * Get authenticated user attributes
 *
 * @returns {Promise<any>}
 */
export function getSession() {
  return getCognitoSession().then((result) => (result ? result.session : null));
}

export async function refresh(sessionData) {
  const { cognitoUser, session } = !sessionData
    ? await getCognitoSession()
    : sessionData;
  const refreshToken = session.getRefreshToken();
  return await promisify((cb) => cognitoUser.refreshSession(refreshToken, cb));
}

export async function getCognitoData() {
  const { cognitoUser } = await getCognitoSession();
  return new Promise((resolve, reject) => {
    cognitoUser.getUserData(
      (err, userData) => {
        if (err) reject(err);
        resolve(userData.UserAttributes);
      },
      { bypassCache: true }
    );
  });
}

/**
 * This is used when the user is not authenticated yet
 *
 * @param email cognito user email
 * @param session cognito session token
 * @returns {module:amazon-cognito-identity-js.CognitoUser}
 */
function createCognitoUser(email, session) {
  const cognitoUser = new CognitoUser({ Username: email, Pool: userPool });
  if (session) {
    cognitoUser.Session = session;
  }
  return cognitoUser;
}

async function getCognitoSession() {
  const cognitoUser = userPool.getCurrentUser();
  if (!cognitoUser) {
    return Promise.resolve(null);
  }

  let session = await promisify((cb) => cognitoUser.getSession(cb));
  if (session.idToken.payload.exp < Date.now() / 1000) {
    session = refresh({ cognitoUser, session });
  }

  return { cognitoUser, session };
}

function promisify(action) {
  return new Promise(function (resolve, reject) {
    function callbackHandler(error, success) {
      if (error) reject(error);
      resolve(success);
    }

    action(callbackHandler);
  });
}

function getRandomPassword() {
  const randomValues = new Uint8Array(30);
  window.crypto.getRandomValues(randomValues);
  return Array.from(randomValues).map(intToHex).join("");
}

function intToHex(nr) {
  return nr.toString(16).padStart(2, "0");
}
