import {
  createUser,
  getUser,
  updateUser,
  getUserProgress,
  updateUserProgress,
} from "@/services/user";

import Vue from "vue";
import { resetState } from "./storeUtils";

export default {
  namespaced: true,
  actions: {
    async get({ commit }) {
      const user = await getUser();
      commit("setUser", user);
      return user;
    },
    async create({ commit }, data) {
      const user = await createUser(data);
      commit("setUser", user);
      return user;
    },
    async update({ commit }, data) {
      const user = await updateUser(data);
      commit("setUser", user);
      return user;
    },
    async getProgress({ commit }, courseId) {
      const progress = await getUserProgress(courseId);
      commit("setProgress", {
        courseId,
        lastCompleted: progress.lastCompleted,
        lessonsPassed: progress.lessonsPassed,
      });
      return progress;
    },
    async updateProgress({ commit }, { courseId, lessonId }) {
      const progress = await updateUserProgress(courseId, lessonId);
      commit("setProgress", {
        courseId,
        lastCompleted: progress.lastCompleted || null,
        lessonsPassed: progress.lessonsPassed || [],
      });
      return progress;
    },
  },
  getters: {
    getUser: (state) => state.user,
    progress: (state) => (courseId) => state.progress[courseId],
    exerciseProgress: (state) => (lessonId) => state.exerciseProgress[lessonId],
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setProgress(state, { courseId, lastCompleted, lessonsPassed }) {
      Vue.set(state.progress, courseId, { lastCompleted, lessonsPassed });
    },
    setExerciseProgress(state, { lessonId, exerciseData }) {
      Vue.set(state.exerciseProgress, lessonId, exerciseData);
    },
    clear: resetState(initialState()),
  },
  state: initialState,
};

export const userActions = {
  get: "user/get",
  create: "user/create",
  update: "user/update",
  getProgress: "user/getProgress",
  updateProgress: "user/updateProgress",
};

export const userGetters = {
  getUser: "user/getUser",
  progress: "user/progress",
  exerciseProgress: "user/exerciseProgress",
};

export const userMutations = {
  clear: "user/clear",
  setExerciseProgress: "user/setExerciseProgress",
};

function initialState() {
  return {
    user: {},
    progress: {},
    exerciseProgress: {},
  };
}
