import { http } from "./axiosUtils";

/**
 * Get the the course. If a lastModified parameter is provided it will return a value only if there is any change
 * @param id course identifier
 * @param lastModified [Optional] If-Modified-Since header value. ex: 2020-06-08T18:30:48.000Z
 * @returns {Promise<AxiosResponse<any>|undefined>} will return undefined in case that the course is not updated since the provided lastModified
 * @throws error with status 401 if user is not authorized or course not available, 404 if course not found.
 */
export const getCourse = async (id, lastModified) => {
  try {
    const config = lastModified && {
      headers: { "If-Modified-Since": lastModified },
    };
    return await http.get(`courses/${id}`, config);
  } catch (e) {
    if (e.status !== 304) {
      throw e;
    }
    return undefined;
  }
};
export const listCourses = () => http.get("courses");
export const enrollFreeCourse = (id) => http.put(`courses/enroll/${id}`);
