<template>
  <div class="vimeo-iframe">
    <iframe
      :src="`https://player.vimeo.com/video/${videoId}`"
      width="640"
      height="480"
      frameborder="0"
      allow="fullscreen"
      allowfullscreen
    />
  </div>
</template>

<script>
export default {
  name: "VimeoVideo",
  props: {
    videoId: String,
  },
};
</script>

<style scoped lang="sass">
.vimeo-iframe
  position: relative
  padding-bottom: min(360px, 56.25%)
  height: 0

  iframe
    position: absolute
    top: 0
    left: 0
    width: 100%
    max-width: 640px
    height: 100%
    max-height: 480px
</style>
