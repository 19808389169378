import Vue from "vue";
import VueRouter from "vue-router";
import Meta from "vue-meta";
import { routePermissions } from "./permissions";

const Auth = () =>
  import(/* webpackChunkName: "public" */ "../views/Public/Auth");
const Shop = () =>
  import(/* webpackChunkName: "public" */ "../views/Public/Shop");
const CodeConfirmation = () =>
  import(/* webpackChunkName: "public" */ "../views/Public/CodeConfirmation");
const App = () => import("../views/App/index");
const UserProfile = () => import("../views/App/UserProfile");
const Welcome = () => import("../views/App/Welcome");
const Home = () =>
  import(/* webpackChunkName: "courses" */ "../views/App/Home");
const Course = () =>
  import(/* webpackChunkName: "courses" */ "../views/App/Course");
const LessonContent = () =>
  import(/* webpackChunkName: "courses" */ "../views/App/LessonContent");

Vue.use(Meta);
Vue.use(VueRouter);

const routes = [
  { path: "/public/shop/:productId", component: Shop },
  { path: "/public/auth", component: Auth },
  { path: "/public/code", component: CodeConfirmation },
  {
    path: "/app",
    component: App,
    children: [
      {
        path: "home",
        component: Home,
        beforeEnter: routePermissions,
      },
      {
        path: "welcome",
        component: Welcome,
        beforeEnter: routePermissions,
      },
      {
        path: "course/:courseId",
        component: Course,
        meta: { hasDrawer: true },
        beforeEnter: routePermissions,
        children: [
          {
            path: ":moduleId/:lessonId",
            component: LessonContent,
            meta: { hasDrawer: true },
            beforeEnter: routePermissions,
          },
        ],
      },
      {
        path: "user/profile",
        component: UserProfile,
        beforeEnter: routePermissions,
      },
    ],
  },
  { path: "*", redirect: "/app/home" },
];

const router = new VueRouter({ mode: "history", routes, scrollBehavior });

export default router;

function scrollBehavior(to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition;
  }
  if (to.hash) {
    return { selector: to.hash };
  }
  return { x: 0, y: 0 };
}
