import Vue from "vue";

const EventBus = new Vue();

Vue.prototype.$support = {
  /**
   * Subscribe to events related with the support form
   * @param handler
   */
  subscribe: (handler) => EventBus.$on("support", handler),
  /**
   * Show Support form. This will last for 2 seconds.
   */
  show: () => EventBus.$emit("support"),
};
