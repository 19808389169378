<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        ¿Acceder a la academia es GRATIS?
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <p>
          Sí, acceder a la academia es <b>GRATIS</b>. Una vez dentro,
          encontrarás formaciones gratuitas y de pago. Si decides acceder a
          estas últimas, serás informado del precio y consciente de realizar el
          pago.
        </p>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header>
        ¿Cómo puedo entrar en la academia?
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <p>Para acceder o registrarte:</p>
        <ol>
          <li>
            <p>
              Introduce tu correo electrónico en la pantalla de inicio y pulsa
              el botón de entrar.
            </p>
          </li>
          <li>
            <p>
              Introduce el código de 6 dígitos que te habrá llegado a tu email
              (revisa la carpeta de Spam, Promociones...).
            </p>
            <p>
              Este código es válido durante 3 minutos, pasado ese tiempo tendrás
              que pedir uno nuevo.
            </p>
          </li>
        </ol>
        <p>
          Una vez dentro, no te volverá a pedir el código en varios meses
          mientras no cierres tu sesión al salir.
        </p>
        <p>
          Si tienes dudas, aquí tienes un vídeo donde te explico cómo hacerlo:
        </p>
        <vimeo-video video-id="393316211" />
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header>
        ¿Tengo qué meter el código siempre que acceda?
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <p>
          La primera vez que accedes a la Academia tienes que meter el código
          que te mandamos,
          <strong
            >pero no tendrás que volver a hacerlo en varios meses mientras que
            no cierres tu sesión</strong
          >. Y en caso de volver a necesitar el código, sería uno nuevo.
        </p>
        <p>
          Esta forma de acceder es muy segura y, además, evita que tengas que
          recordar una contraseña o meterla cada vez que quieras acceder.
        </p>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header>
        No recibo ningún correo con el código para acceder, ¿qué hago?
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <p>
          A veces el correo que te mandamos con el código termina en la carpeta
          de Spam, Promociones, Correo no deseado, Otros, etc.
          <b>Por favor, revisa que no esté en ninguna de estas carpetas.</b>
        </p>
        <p>
          Si utilizas GMAIL, revisa que no esté en la carpeta de Promociones,
          Notificaciones o Spam
        </p>
        <v-img width="640" src="./gmail-otros.png" />
        <p>
          Si utilizas HOTMAIL O OUTLOOK, revisa que no esté en la carpeta de
          Otros o Correo no deseado.
        </p>
        <v-img width="640" src="./hotmail-otros.png" />
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header>
        Tengo problemas al validar el código, ¿qué está pasando?
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <p>Puede estar ocurriendo varias cosas:</p>
        <h3>EL CÓDIGO HA CADUCADO</h3>
        <p>
          El código tiene una <b>validez de 3 minutos</b> desde que lo recibes
          hasta que lo introduces para entrar a la academia. Pasado ese tiempo
          ya no es válido y tienes que pedir uno nuevo.
        </p>
        <p>
          Sí, esto es un poco rollo, pero es por la seguridad de tus datos.
          Recuerda que una vez hecho, no tendrás que volver a introducir ningún
          código en varios meses siempre que no cierres sesión (ni tampoco
          recordar ninguna contraseña).
        </p>
        <h3>CUANDO INTENTO METER EL CÓDIGO, ME PIDE OTRA VE EL EMAIL</h3>
        <p>
          Si cuando vas a introducir el código vuelves a estar en la pantalla de
          inicio, no metas otras vez el email pues te mandaremos un nuevo código
          (dejando de ser válido el que ibas a introducir).
        </p>
        <p>
          Clica en el botón justo debajo del email que dice
          <span style="color: #d46a00">¿YA PEDISTE UN CÓDIGO?</span> para
          introducir el código.
        </p>
        <v-img width="640" src="./ya-pediste-código.png" />
        <h3>EL CÓDIGO NO ES CORRECTO</h3>
        <p>
          Revisa que el código que estás introduciendo es el correcto y el
          último que te hemos mandado.
        </p>

        <p>Si aún te quedan dudas, te explico todo esto en este vídeo:</p>
        <vimeo-video video-id="393321562" />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import VimeoVideo from "../VimeoVideo";

export default {
  name: "FaqScreen",
  components: { VimeoVideo },
  data() {
    return {
      tab: 0,
    };
  },
};
</script>

<style scoped lang="sass">
@import "../../../styles/custom-colors"
h3
  margin-top: 10px
  margin-bottom: 10px
  color: $dark-purple

.v-image
  margin-top: 20px
  margin-bottom: 20px

.v-expansion-panel-header
  background-color: white
  color: $dark-purple
  font-size: calc(12px + 1vh)

.v-expansion-panel-content
  background-color: white
  font-size: calc(9px + 1vh)
</style>
