import Vue from "vue";

const EventBus = new Vue();

Vue.prototype.$loading = {
  /**
   * Subscribe to events related with the loading
   * @param handleLoading
   * @param handleStopLoading
   */
  subscribe: (handleLoading, handleStopLoading) => {
    EventBus.$on("loading", handleLoading);
    EventBus.$on("stop-loading", handleStopLoading);
  },
  loading: () => EventBus.$emit("loading"),
  stopLoading: () => EventBus.$emit("stop-loading"),
};
