export const emailRules = [
  (v) => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(v) || "El correo electrónico no es válido";
  },
];

export const passwordRules = [
  (v) =>
    v.length > 13 ||
    "Por seguridad, la longitud mínima es 14. ¡Pon una frase para recordar!",
  (v) => /[a-z]/.test(v) || "Debe contener al menos una letra minúscula",
];

export function confirmPasswordRule(password, confirmPassword) {
  return [password === confirmPassword || "Both password should be the same"];
}

export const requiredRule = [(v) => !!v || "Campo obligatorio"];
