import { register } from "register-service-worker";
import Vue from "vue";

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {},
    registered() {},
    cached() {},
    updatefound() {},
    updated(registration) {
      Vue.prototype.$snackbar.info("Nueva versión disponible!", {
        text: "Actualizar",
        action: () =>
          registration.waiting.postMessage({ action: "skipWaiting" }),
      });
    },
    offline() {},
    error(error) {},
  });
}

let refreshing;
navigator.serviceWorker.addEventListener("controllerchange", function () {
  if (refreshing) return;
  window.location.reload();
  refreshing = true;
});
