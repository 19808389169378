<template>
  <v-container style="max-width: none">
    <v-row>
      <v-col>
        <v-row>
          <v-form v-model="valid" style="width: 100%">
            <v-row>
              <v-col>
                Si necesitas ayuda, estamos a tu disposición para solucionar
                cualquier problema en <b>hola@perrosdeciudad.com</b> o a través
                de este formulario:
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  outlined
                  background-color="white"
                  class="form-field"
                  v-model="message.text"
                  label="Mensaje"
                  :rules="requiredRule"
                />
              </v-col>
            </v-row>
            <v-row justify="space-between" align="center">
              <v-col cols="12" sm="8" md="6">
                <v-text-field
                  background-color="white"
                  outlined
                  class="form-field"
                  label="Correo electrónico"
                  v-model="message.email"
                  :rules="emailRule.concat(requiredRule)"
                />
              </v-col>
              <v-col cols="12" sm="4">
                <div class="flex-grow-1" />
                <v-progress-circular
                  indeterminate
                  class="mr-5"
                  color="primary"
                  v-if="loading"
                />
                <v-btn
                  style="width: 100%"
                  class="form-button"
                  @click="sendMessage"
                  :disabled="!valid"
                >
                  Enviar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { sendMessage } from "../../../services/contact";
import { emailRules, requiredRule } from "../../../model/validationRules";
import { authGetters } from "../../../store/auth";

export default {
  name: "SupportForm",
  data() {
    return {
      emailRule: emailRules,
      requiredRule: requiredRule,
      valid: false,
      loading: false,
      message: {
        text: undefined,
        email: this.$store.getters[authGetters.getLoggedUser].email,
        subject: "Support request",
      },
    };
  },
  methods: {
    async sendMessage() {
      this.loading = true;
      try {
        await sendMessage(this.message);
        this.$snackbar.info(
          "Hemos recibido tu mensaje, muchas gracias. Te contestaremos lo antes posible."
        );
        this.message.text = "";
      } catch (e) {
        this.$snackbar.error(
          "¡Ups! No se ha enviado tu mensaje, prueba más tarde o escríbenos a: hola@perrosdeciudad.com"
        );
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="sass">
@import "src/styles/forms"
</style>
