import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

const theme = {
  primary: "#1976D2",
  secondary: "#b0bec5",
  accent: "#8c9eff",
  error: "#b71c1c",
  info: "#2196F3",
  success: "#4CAF50",
  warning: "#FFC107",
  anchor: "#8c9eff",
  black: "#3C3533",
  red: "#de7851",
  brown: "#e1a984",
  cream: "#FAECDD",
  cream_light: "#FCF2E4",
  cream_transparent: "#fdfff5",
  blue: "#183B48",
};

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: theme,
    },
  },
});
