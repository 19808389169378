import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";

import auth, { authMutations } from "./auth";
import courses, { coursesMutations } from "./courses";
import user, { userMutations } from "./user";

Vue.use(Vuex);

const localstorageKey = "academia";
const vuexPersist = new VuexPersist({
  key: localstorageKey,
  reducer: (state) => ({
    auth: state.auth,
    courses: state.courses,
    user: state.user,
    oldPath: state.route.fullPath,
  }),
});

const base = {
  actions: {
    async clear({ commit }) {
      commit(coursesMutations.clear);
      commit(userMutations.clear);
      commit(authMutations.clear);
      commit("setFirstVisit", true);
    },
  },
  getters: {
    isDrawerVisible: (state) => state.isDrawerVisible,
    isFirstVisit: (state) => state.firstVisit,
    getOldPath: (state) => state.oldPath || "",
    getInstallPrompt: (state) => state.installPrompt,
  },
  mutations: {
    showDrawer(state, value) {
      state.isDrawerVisible = value;
    },
    setFirstVisit(state, value) {
      state.firstVisit = value;
    },
    setInstallPrompt(state, value) {
      state.installPrompt = value;
    },
  },
  state: {
    isDrawerVisible: false,
    installPrompt: undefined,
    firstVisit: true,
  },
};

const store = new Vuex.Store({
  ...base,
  modules: { auth, courses, user },
  plugins: [vuexPersist.plugin],
});

export default store;
