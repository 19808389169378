import { enrollFreeCourse, getCourse, listCourses } from "@/services/courses";
import { resetState } from "./storeUtils";
import Vue from "vue";

export default {
  namespaced: true,
  actions: {
    async list({ commit }) {
      const courses = await listCourses();
      commit("setCourses", courses);
    },
    async get({ commit }, { courseId, lastModified }) {
      const course = await getCourse(courseId, lastModified);
      if (course) {
        commit("setCourse", { courseId, course });
      }
    },
    enrollFreeCourse(_, courseId) {
      return enrollFreeCourse(courseId);
    },
  },
  getters: {
    list: (state) => state.courseList,
    courses: (state) => state.courses,
  },
  mutations: {
    setCourses(state, courses) {
      state.courseList = courses;
    },
    setCourse(state, { courseId, course }) {
      Vue.set(state.courses, courseId, course);
    },
    clear: resetState(initialState()),
  },
  state: initialState,
};

export const coursesGetters = {
  list: "courses/list",
  courses: "courses/courses",
};

export const coursesActions = {
  list: "courses/list",
  enrollFreeCourse: "courses/enrollFreeCourse",
  get: "courses/get",
};

export const coursesMutations = {
  clear: "courses/clear",
};

function initialState() {
  return {
    courseList: [],
    courses: {},
  };
}
