import { http } from "@/services/axiosUtils";

const path = "authentication";

/**
 * Save pre authorization session data.
 *
 * @param sessionData.email
 * @param sessionData.session
 * @returns {Promise<AxiosResponse<T>>}
 */
export const savePreAuthSession = (sessionData) => http.post(path, sessionData);

export const getPreAuthSession = (email) => http.get(`${path}/${email}`);
