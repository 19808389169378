import Vue from "vue";

const EventBus = new Vue();

Vue.prototype.$snackbar = {
  /**
   * Subscribe to events related with the snackbar
   * @param handler
   */
  subscribe: (handler) => EventBus.$on("snackbar", handler),
  /**
   * Show a success message in the snackbar. This will last for 2 seconds.
   * @param message message to show
   * @param button {text,action} shows a button to make extra action.
   * Action is a function that not takes any param.
   * text should be a short message (one word or two)
   */
  success: (message, button) =>
    EventBus.$emit("snackbar", {
      color: "success",
      text: message,
      timeout: 2000,
      button,
    }),
  /**
   * Show a error message in the snackbar. This will last for 6 seconds
   * @param message message to show
   * @param button {text,action} shows a button to make extra action.
   * Action is a function that not takes any param.
   * text should be a short message (one word or two)
   */
  error: (message, button) =>
    EventBus.$emit("snackbar", {
      color: "error",
      text: message,
      timeout: 0,
      button,
    }),
  /**
   * Show a info message in the snackbar. This has to be closed manually.
   * @param message message to show
   * @param button {text,action} shows a button to make extra action.
   * Action is a function that not takes any param.
   * text should be a short message (one word or two)
   */
  info: (message, button) =>
    EventBus.$emit("snackbar", {
      color: "info",
      text: message,
      timeout: 6000,
      button,
    }),
};
