<template>
  <v-snackbar
    v-model="visible"
    :color="snackbar.color"
    :timeout="snackbar.timeout"
    :multi-line="true"
    :top="true"
  >
    {{ snackbar.text }}
    <v-btn text @click="action" v-if="snackbar.button" color="black">
      {{ snackbar.button.text }}
    </v-btn>
    <v-btn text @click="close" v-if="snackbar.timeout === 0">
      <v-icon>mdi-close-circle-outline</v-icon>
    </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      snackbar: {
        text: "",
        timeout: 6000,
        color: "",
      },
    };
  },
  created() {
    this.$snackbar.subscribe((data) => {
      this.snackbar = data;
      this.visible = true;
    });
  },
  methods: {
    close() {
      this.visible = false;
    },
    action() {
      this.close();
      this.snackbar.button.action();
    },
  },
};
</script>

<style scoped></style>
