import {
  getCognitoData,
  getSession,
  refresh,
  signOut,
} from "@/services/authentication";
import Vue from "vue";
import { resetState } from "./storeUtils";

export default {
  namespaced: true,
  actions: {
    async logout({ commit }) {
      await signOut();
      commit("clear");
    },
    async userSession({ commit }) {
      const userSession = await getSession();
      commit("setUserSession", userSession ? userSession.idToken : {});
      return userSession ? userSession.idToken.payload : undefined;
    },
    async refreshToken({ commit }) {
      const userSession = await refresh();
      commit("setUserSession", userSession ? userSession.idToken : {});
    },
    async cognitoData({ commit }) {
      const userAttributes = await getCognitoData();
      userAttributes.forEach((att) => commit("setUserSessionAttribute", att));
      return userAttributes;
    },
  },
  getters: {
    getLoggedUser: (state) => {
      const idToken = state.userSession.idToken;
      return idToken && idToken.payload ? idToken.payload : {};
    },
    getCourseClaim: (s, getters) => {
      return (courseId) => {
        const allowedCoursesString =
          getters.getLoggedUser["custom:allowedCourses"];
        if (allowedCoursesString) {
          const allowedCourses = allowedCoursesString.split(";");
          for (let allowedCoursesKey of allowedCourses) {
            const [id, startDate] = allowedCoursesKey.split(":");
            if (id === courseId) {
              return { startDate };
            }
          }
        }
      };
    },
    userIsLogged: (state, getters) => {
      return !!getters.getLoggedUser.email;
    },
    getLoggedUserToken: (state) => {
      const idToken = state.userSession.idToken;
      return idToken ? idToken.jwtToken : "";
    },
  },
  mutations: {
    setUserSession(state, idToken) {
      Vue.set(state.userSession, "idToken", idToken);
    },
    setUserSessionAttribute(state, attribute) {
      Vue.set(
        state.userSession.idToken.payload,
        attribute.Name,
        attribute.Value
      );
    },
    setUserPreAuthSession(state, session) {
      Vue.set(state, "userPreAuthSession", session);
    },
    clear: resetState(initialState()),
  },
  state: initialState,
};

export const authActions = {
  startAuthorizationSession: "auth/startAuthorizationSession",
  authorizationStep: "auth/authorizationStep",
  logout: "auth/logout",
  userSession: "auth/userSession",
  cognitoData: "auth/cognitoData",
  refreshToken: "auth/refreshToken",
};

export const authGetters = {
  isPasswordChangeRequired: "auth/isPasswordChangeRequired",
  getLoggedUser: "auth/getLoggedUser",
  getCourseClaim: "auth/getCourseClaim",
  userIsLogged: "auth/userIsLogged",
  getLoggedUserToken: "auth/getLoggedUserToken",
};

export const authMutations = {
  clear: "auth/clear",
};

function initialState() {
  return {
    userSession: {},
  };
}
